import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { ENVIO } from "../constants";

const loadEnvios = loadRequest(ENVIO);
const insertEnvio = insertRequest(ENVIO);
const updateEnvio = updateRequest(ENVIO);
const trashEnvio = trashRequest(ENVIO);
const untrashEnvio = untrashRequest(ENVIO);
const removeEnvio = removeRequest(ENVIO);

function* rootSaga() {
	yield all([
		loadEnvios(),
		insertEnvio(),
		updateEnvio(),
		removeEnvio(),
		trashEnvio(),
		untrashEnvio()
	]);
}
export default rootSaga;