import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { MEDIDA } from "../constants";

const loadMedidas = loadRequest(MEDIDA);
const insertMedida = insertRequest(MEDIDA);
const updateMedida = updateRequest(MEDIDA);
const trashMedida = trashRequest(MEDIDA);
const untrashMedida = untrashRequest(MEDIDA);
const removeMedida = removeRequest(MEDIDA);

function* rootSaga() {
	yield all([
		loadMedidas(),
		insertMedida(),
		updateMedida(),
		removeMedida(),
		trashMedida(),
		untrashMedida()
	]);
}
export default rootSaga;