import React from "react";
import moment from "moment";
import "moment/locale/es";

export default function DateCell ({ cell }){
	return (
		<>
			<div>
				{cell.value && moment(cell.value).format(cell.column?.format || "DD/MM/YYYY")}
			</div>
		</>
	);
}