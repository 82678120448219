import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./routes/Routes";
import store from "./store/reducers/index";
// import "./App.css";
// eslint-disable-next-line no-undef
window.store= store;
function App() {
	return (
		<Router>
			<Switch>
				<Routes />
			</Switch>
		</Router>
	);
}

export default App;
