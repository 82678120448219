import rootSaga from "../sagas/index";
import createSagaMiddleware from "redux-saga";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import crudReducer from "./crudReducer";
import {
	ACABADO,
	CLIENTE,
	COMPRA,
	CREDITO,
	ENVIO,
	MEDIDA,
	PEDIDO,
	PRODUCTO,
} from "../constants";

var emptyReducer = () => ({});


const acabados = crudReducer(emptyReducer, ACABADO.name, {
	data: [],
	view: {}
});

const clientes = crudReducer(emptyReducer, CLIENTE.name, {
	data: [],
	view: {}
});
const compras = crudReducer(emptyReducer, COMPRA.name, {
	data: [],
	view: {}
});

const creditos = crudReducer(emptyReducer, CREDITO.name, {
	data: [],
	view: {}
});
const envios = crudReducer(emptyReducer, ENVIO.name, {
	data: [],
	view: {}
});
const medidas = crudReducer(emptyReducer, MEDIDA.name, {
	data: [],
	view: {}
});
const pedidos = crudReducer(emptyReducer, PEDIDO.name, {
	data: [],
	view: {}
});
const productos = crudReducer(emptyReducer, PRODUCTO.name, {
	data: [],
	view: {}
});


const sagaMiddleware = createSagaMiddleware();

const app = combineReducers({
	[ACABADO.name]:acabados,
	[CLIENTE.name]: clientes,
	[COMPRA.name]:compras,
	[CREDITO.name]:creditos,
	[ENVIO.name]: envios,
	[MEDIDA.name]:medidas,
	[PEDIDO.name]:pedidos,
	[PRODUCTO.name]:productos,


});
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
// const composeEnhancers =
//   typeof window === 'object' &&
//   window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//     }) : compose;
const composerEnhancers = composeWithDevTools({
	name: "Redux",
	realtime: true,
	trace: true,
	traceLimit: 25
});
const enhancer = composerEnhancers(
	applyMiddleware(sagaMiddleware),
	// other store enhancers if any
);
const store = createStore(app, enhancer);

// var store = createStore(app, applyMiddleware(sagaMiddleware),window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
sagaMiddleware.run(rootSaga);

export default store;
