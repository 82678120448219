import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import RefCell from "../../components/Table/CellTypes/RefCell/RefCell";
import { useSelector } from "react-redux";
import PedidoModal from "./Pedido";
import { PEDIDO, CLIENTE } from "store/constants";
import Header from "../../components/Header/Header";
import { Grid, CircularProgress, Button } from "@material-ui/core";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";

import api from "api";
const TabTareas = () => {
	const data = useSelector(state => state[PEDIDO.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Cliente",
				Cell:RefCell,
				type:CLIENTE,
				accessor: "id_cliente"
			},
			{
				Header: "Fecha",
				accessor: "fecha",
				filter:"daterange",
				Cell:DateCell
			},
			// {
			// 	Header: "Total",
			// 	accessor: "total"
			// },
			{
				Header: "Estado",
				accessor: "estado"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Pedidos" video time="5:35" />
			

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={PEDIDO}
				modal={PedidoModal}
				filters={columns}
				actions={[({cell})=><Button variant="contained" color="primary" style={{margin:"5px"}} onClick={()=>api.download(`pedidos/${cell.row.original._id}/zip`)}>Descargar</Button>]}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabTareas;
