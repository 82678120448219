import React, {useMemo, useState, useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {Button, IconButton} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ClearIcon from "@material-ui/icons/Delete";
import PropTypes from "prop-types";
import { FileCopySharp } from "@material-ui/icons";

const baseStyle = {
	flex: 1,
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	padding: "20px",
	borderWidth: 2,
	borderRadius: 2,
	borderColor: "#eeeeee",
	borderStyle: "dashed",
	backgroundColor: "#fafafa",
	color: "#bdbdbd",
	outline: "none",
	transition: "border .24s ease-in-out"
};

const activeStyle = {
	borderColor: "#2196f3"
};

const acceptStyle = {
	borderColor: "#00e676"
};

const rejectStyle = {
	borderColor: "#ff1744"
};
const filesString = "application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow";
export default function StyledDropzone(props) {
	console.log(props);
	const [changing, setChanging] = useState(false);
	const onDrop = useCallback(acceptedFiles => {
		// Do something with the files
		console.log(props.multipleFiles);
		console.log(acceptedFiles);
		if(acceptedFiles.length>0){
			
			if(props.multipleFiles){
				setChanging(true);

				console.log("isMultiple");
				props.onFile({files: acceptedFiles}, props.name, props.single);
			}else{
				setChanging(false);
				props.onFile({files: acceptedFiles[0]}, props.name, props.single);
			}
			
		}
	}, []);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject
	} = useDropzone({
		accept: props.accept ? props.accept : (props.acceptFiles ? filesString : "image/*"),
		onDrop
	});

	const style = useMemo(() => ({
		...baseStyle,
		...(isDragActive ? activeStyle : {}),
		...(isDragAccept ? acceptStyle : {}),
		...(isDragReject ? rejectStyle : {})
	}), [
		isDragActive,
		isDragReject
	]);
	const getFullUrl = (file) => `${process.env.REACT_APP_FILE_URL}${file}`;
	const circleStyle = props.circle ? {borderRadius: "50%", height: "200px", margin: "auto", objectFit:"cover"} : {};

	const files = props?.files?.filter(f=>f.name===props.name);
	const value = props.value ? Array.isArray(props.value) ? props.value : [props.value] : [];
	return (
		<>
			{value.map(v=>{
				let renderItem;
				if(v.tipo?.includes("image") || v.tipo?.includes("png") || v.tipo?.includes("jpeg") || v.tipo?.includes("jpg")){
					renderItem = <div style={{height:"100%"}}>
						<img style={{ width: "200px", ...circleStyle}} src={`${process.env.REACT_APP_FILE_URL}${v.path}`} />
					</div>;
				}
				else if(v.tipo?.includes("video")){
					renderItem = <>
						<video width="320" height="240" controls>
							<source src={getFullUrl(props.value.path)} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
						<br />
					</>;
				}
				else{
					renderItem = <div>{v.tipo}</div>;
				}
				renderItem = <>
					{renderItem}
					<a target="_blank" rel="noopener noreferrer" href={getFullUrl(v.path)} download>
						<IconButton><GetAppIcon /></IconButton>
					</a>
				</>;
				
				return renderItem;
			})}

			{props.value &&
				<Button variant="contained" color="primary" size="small" onClick={()=>setChanging(true)}>
					Cambiar
				</Button>
			}

			{files?.length>0 && 
				<div style={{display: "flex", margin:"5px 0",flexDirection:"column"}}>
					{files.map(file=>
						<div key={file._id} style={{display:"flex"}}>
							<ClearIcon onClick={() => props.removeFile(file.file)} fontSize="small" style={{color:"red"}} />
							<p>{file.file.name}</p>
						</div>
					)}
				</div>
			}
			{((!props.value && (!files || files?.length===0)) || changing) &&
				// (((!files || files?.length===0) && !props.value)||changing||props.multipleFiles) &&
				<>
					<div className="container">
						<div {...getRootProps({style})}>
							<input {...getInputProps()} />
							<p>Arrastra tu archivo, o da click para seleccionar</p>
						</div>
					</div>
				</>

			}

			
		</>
		// <div>
		// 	{(props.value?.tipo?.includes("image") || props.value?.tipo?.includes("png") || props.value?.tipo?.includes("jpeg") || props.value?.tipo?.includes("jpg") )
		// 		? 
		// 		<div style={{height:"100%"}}>
		// 			<img style={{ width: "200px", ...circleStyle}} src={`${process.env.REACT_APP_FILE_URL}${props.value.path}`} />
		// 		</div>
		// 		:
		// 		props.value?.tipo?.includes("video") ? 
		// 			<>
		// 				<video width="320" height="240" controls>
		// 					<source src={getFullUrl(props.value.path)} type="video/mp4" />
		// 					Your browser does not support the video tag.
		// 				</video>
		// 				<br />
		// 			</>
		// 			:
    //     	props.value?.tipo
		// 	}
		// 	{(props.value && props.download) &&
		// 		<a target="_blank" rel="noopener noreferrer" href={`${process.env.REACT_APP_FILE_URL}${props.value.path}`} download><IconButton><GetAppIcon /></IconButton></a>

		// 	}
		// 	{((!props.value && !props.files) || changing || props.multipleFiles) ?
		// 		<>
		// 			<div className="container">
		// 				<div {...getRootProps({style})}>
		// 					<input {...getInputProps()} />
		// 					<p>Arrastra tu archivo, o da click para seleccionar</p>
		// 				</div>
		// 			</div>
		// 		</>
		// 		:
		// 		props.files?.find(f=>f.name===props.name) ?
		// 			props.files?.find(f=>f.name===props.name)?.file.name
		// 			:
					// <Button variant="contained" color="primary" size="small" onClick={()=>setChanging(true)}>
					// 	Cambiar
					// </Button>
		// 	}
		// 	{props.multipleFiles &&
		// 			props.files?.map(file=>(
		// 				<div style={{display: "flex", margin:"5px 0"}}>
		// 					<ClearIcon onClick={() => props.removeFile(file.file)} fontSize="small" style={{color:"red"}} />
		// 					<p>{file.file.name}</p>
		// 				</div>
		// 			))
		// 	}
		// </div>
	);
}
// function Image(){
// 	return <div style={{height:"100%"}}>
// 		<img style={{ width: "200px", ...circleStyle}} src={`${process.env.REACT_APP_FILE_URL}${props.value.path}`} />
// 	</div>
// }
// function Video(){
	
// }

StyledDropzone.propTypes = {
	onFile: PropTypes.func,
	name: PropTypes.string,
	single: PropTypes.bool,
	accept: PropTypes.bool,
	acceptFiles: PropTypes.bool,
	circle: PropTypes.bool,
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	download: PropTypes.bool,
	files: PropTypes.object
};