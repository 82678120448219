import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";

const Receta = props => {
	const elements = React.useMemo(()=>[
		{
			type: "ref",
			name: "id_cliente",
			label: "Cliente",
			selector:(state)=>state.CLIENTE.data
		},
		{
			type: "text",
			name: "cantidad",
			label: "Cantidad",
		},
		{
			type: "text",
			name: "precio",
			label: "Precio",
		},
		{
			type: "calendar",
			name: "fecha",
			label: "Fecha",
		},
	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
		</>
	);
};

export default Receta;

Receta.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};