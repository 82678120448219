import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {Redirect} from 'react-router-dom'

const Home = () => {
	return (
		<div>
			<Redirect to="/cursos" />
		</div>
	);
};

export default Home;
