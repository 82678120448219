import React from "react";
import PropTypes from "prop-types";
import { Card, Typography } from "@material-ui/core";
import InfoTooltip from "components/Tooltip/Info";

export default function Box(props) {
	// console.log(props.element.info)
	return (
		<Card
			variant="outlined"
			padding="20px"
			className={props.className}
			style={{
				height: "100%",
				background: "#F6F6F6",
				padding: "20px",
				margin:props.fullWidth?"unset":"auto",
				...props.style
			}}>
			<div style={{display:"flex", justifyContent:"space-between"}}>
				<div>

					<Typography style={{minHeight:"15px"}} variant="h5">{props.index} {props.label}</Typography>
					<Typography variant="subtitle1">
						{props.sublabel}
					</Typography>
				</div>
				{(props?.info || props?.element?.info) && 
					<InfoTooltip info={props.info || props.element.info} />
				}
			</div>
			<Card
				variant="outlined"
				padding="20px"
				style={{ height: "calc(100% - 30px)", padding: "20px", width:"100%"}}>
				{props.children}
			</Card>
		</Card>
	);
}
Box.propTypes = {
	fullWidth: PropTypes.bool,
	label: PropTypes.string,
	subLabel: PropTypes.string,
	style: PropTypes.object,
	children: PropTypes.element.isRequired
};