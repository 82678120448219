import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { ACABADO } from "../constants";

const loadAcabados = loadRequest(ACABADO);
const insertAcabado = insertRequest(ACABADO);
const updateAcabado = updateRequest(ACABADO);
const trashAcabado = trashRequest(ACABADO);
const untrashAcabado = untrashRequest(ACABADO);
const removeAcabado = removeRequest(ACABADO);

function* rootSaga() {
	yield all([
		loadAcabados(),
		insertAcabado(),
		updateAcabado(),
		removeAcabado(),
		trashAcabado(),
		untrashAcabado()
	]);
}
export default rootSaga;