import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import AcabadoModal from "./Acabado";
import { ACABADO } from "store/constants";
import Header from "../../components/Header/Header";

const TabTareas = () => {
	const data = useSelector(state => state[ACABADO.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Acabados" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={ACABADO}
				modal={AcabadoModal}
				filters={columns}
				options={{ orderBy: true, }}
			/>
		</>
	);
};

export default TabTareas;
