import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import RefCell from "../../components/Table/CellTypes/RefCell/RefCell";
import { useSelector } from "react-redux";
import CompraModal from "./Compra";
import { COMPRA, CLIENTE } from "store/constants";
import Header from "../../components/Header/Header";
import DateCell from "components/Table/CellTypes/DateCell/DateCell";

const TabTareas = () => {
	const data = useSelector(state => state[COMPRA.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Cliente",
				Cell:RefCell,
				type:CLIENTE,
				accessor: "id_cliente"
			},
			{
				Header: "Cantidad",
				accessor: "cantidad"
			},
			{
				Header: "Precio",
				accessor: "precio"
			},
			{
				Header: "Fecha",
				accessor: "fecha",
				filter:"daterange",
				Cell:DateCell
			}
		],
		[]
	);

	return (
		<>
			<Header title="Creditos" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={COMPRA}
				modal={CompraModal}
				filters={columns}
				options={{ orderBy: true, disableInsert:true, disableDelete:true }}
			/>
		</>
	);
};

export default TabTareas;
