import React from "react";
import {useSelector} from "react-redux";
import Select from "components/FormComponents/Select/Select";
import {Autocomplete} from "@material-ui/lab";
import {TextField} from "@material-ui/core";
import "./RefFilter.css";
// This is a custom filter UI for selecting
// a unique option from a list
export default function SelectColumnFilter(props) {
	const {
		column: { filterValue, setFilter, id },
	} = props;

	let data = useSelector(state=>state[props.column.type.name].data);
	data = data?.filter(d=>!d._trash);
	data = data?.sort((rowA, rowB) => {
		let a = rowA.nombre;
		let b = rowB.nombre;
		a = a ? a.toLowerCase() : a;
		b = b ? b.toLowerCase() : b;
		return a === b ? 0 : a > b ? 1 : -1;
	});
	const options = React.useMemo(() => {
		return [{value:"",label:"Todos"}, ...new Set(data.map(d=>({label:d.nombre||"", value:d._id})))];
	}, [data]);

	
	const handleChange = (e, v) => {
		setFilter(v? v.value: "");
	};

	return (
		<Autocomplete
			className="refFilter"
			size="small"
			openOnFocus
			options={options||[]}
			value={filterValue}
			getOptionLabel={(option) => option.label}
			onChange={handleChange}
			// style={{ width: 150 }}
			renderInput={(params) => (
				<TextField {...params}  variant="outlined" />
			)}
		/>

		// <Select options={options} onChange={setFilter} />
		// <select
		// 	value={filterValue}
		// 	onChange={e => {
		// 		setFilter(e.target.value || undefined);
		// 	}}
		// >
		// 	<option value="">All</option>
		// 	{options.map((option, i) => (
		// 		<option key={i} value={option.id}>
		// 			{option.label}
		// 		</option>
		// 	))}
		// </select>
	);
}
	