import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";

const Receta = props => {
	const elements = React.useMemo(()=>[
		
		{
			type: "text",
			name: "nombre",
			label: "Nombre",
		},
		{
			type: "text",
			subtype:"number",
			name: "cantidad",
			label: "Cantidad",
		},
		{
			type: "text",
			subtype:"number",
			name: "precio",
			label: "Precio",
		},
		{
			type: "bool",
			name: "recurrente",
			label: "Recurrente",
		},
	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
		</>
	);
};

export default Receta;

Receta.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};