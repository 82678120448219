import React from "react";
import ModalForm from "components/FormComponents/ModalForm/ModalForm";
import PropTypes from "prop-types";

const Receta = props => {
	const elements = React.useMemo(()=>[
		{
			type: "text",
			subtype:"number",
			name: "min_fotos_envio_gratis",
			label: "Minimo fotos para envio gratis",
		},
		{
			type: "text",
			name: "titulo",
			label: "Titulo",
		},
		{
			type: "text",
			name: "descripcion",
			label: "Descripcion",
		},
		{
			type: "text",
			subtype:"number",
			name: "precio",
			label: "Precio",
		},
		
	],[]);
	return (
		<>
			<ModalForm elements={elements} onChange={props.onChange} data={props.data} {...props}></ModalForm>
		</>
	);
};

export default Receta;

Receta.propTypes = {
	onChange: PropTypes.func,
	data: PropTypes.object
};