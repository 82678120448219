import { createMuiTheme } from "@material-ui/core/styles";

const Theme = createMuiTheme({
	palette: {
		primary: {
			main: "#435CF0",
			contrastText: "#fff"
		},
		secondary: {
			main: "#3B4C4B",
			contrastText: "#fff"
		},
		warning: {
			main: "#FF0000"
		}
	},
	overrides: {
		MuiBox: {
			root: {
				padding: 0
			}
		},
		MuiButton: {
			root: {
				minWidth: "100px"
			}
		},
		MuiTabs: {
			root: {
				marginBottom: "10px"
			},
			flexContainer: {
				background: "#f8f8f8"
			},
			indicator: {
				height: "5px"
			},
		},
		MuiTab: {
			textColorInherit: {
				opacity: "1"
			}
			// wrapper: {
			//   color: "#97D95B"
			// }
		},
		MuiSvgIcon:{
			colorPrimary: {
				color: "#ffffff"
			}
		},
		MuiIcon: {
			colorPrimary: {
				color: "#ffffff"
			}
		},
		MuiPaper: {
			elevation4: {
				boxShadow: "none"
			}
		},
		MuiTypography: {
			h5: {
				fontSize: "14pt",
				fontWeight: "bold"
			}
		},
		MuiFormGroup: {
			root: {
				flexWrap: "initial"
			}
		},
		MuiFormControl: {
			root: {
				width: "100%"
			}
		},
	}
});


export default Theme;
