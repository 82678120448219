import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { PEDIDO } from "../constants";

const loadPedidos = loadRequest(PEDIDO);
const insertPedido = insertRequest(PEDIDO);
const updatePedido = updateRequest(PEDIDO);
const trashPedido = trashRequest(PEDIDO);
const untrashPedido = untrashRequest(PEDIDO);
const removePedido = removeRequest(PEDIDO);

function* rootSaga() {
	yield all([
		loadPedidos(),
		insertPedido(),
		updatePedido(),
		removePedido(),
		trashPedido(),
		untrashPedido()
	]);
}
export default rootSaga;