import React, { useState, useEffect } from "react";
import { useImmer } from "use-immer";
import Box from "components/Box/Box";
import "./TableEquivalencias.scss";
import grupo from "misc/grupos";

const TableEquivalencias = (props) => {
	const [equivalencias, setEquivalencias] = useImmer(props.value?props.value:{
		cereales: undefined,
		proteinas: undefined,
		grasas: undefined,
		frutas: undefined,
		verduras: undefined,
		lacteos: undefined,
		azucares: undefined,
		agua: undefined,
		leguminosas: undefined
	});
	const [calorias, setCalorias] = useState(0);

	useEffect(() => {
		props.onChange(equivalencias, props.name);
		
		const cal = grupo.reduce((acc,v)=>{
			acc += (equivalencias[v.name] ? equivalencias[v.name] : 0) * v.cal;
			return acc;
		},0);
		setCalorias(cal);

	}, [equivalencias]);

	return (
		<>
			<Box>
				<table className="content-table-equivalencias">
					<tbody>
						{grupo.map((value, i) => (
							<tr key={i} className="row-body" style={{background:value.color+"19"}}>
								<th style={{display: "flex"}}>
									<img
										style={{float:"left", objectFit: "contain"}}
										src={require(`assets/img/${value.name}.png`)}
										alt={value.label}
										className="img-category"
									/>
									<div style={{color:value.color,margin:"auto",marginLeft:0}}>{value.label}</div>
								</th>
								<>
									<td>
										<input
											className="eqInput"
											type="number"
											id=""
											value={equivalencias[value.name]}
											placeholder={0}
											onChange={e => {
												e.persist();
												setEquivalencias(draft => {
													draft[value.name] = e.target.value ? Number(e.target.value) : 0;
												});
											}}
										/>
									</td>
								</>
							</tr>
						))}
					</tbody>
				</table>
			</Box>
			<div style={{marginLeft:"20px", fontWeight:"bold", marginTop:"10px"}}>Calorías totales<div style={{float:"right"}}>{calorias} Cal</div></div>
		</>
	);
};

export default TableEquivalencias;
