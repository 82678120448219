import React, { useEffect } from "react";
import { generatePath, Link } from "react-router-dom";
import {useLocation} from "react-router-dom";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";

import "./Button.scss";


const NavLink = (props) => {
	const me = useSelector(state=>state.CUSTOMER);
	

	const location = useLocation();
	let isActive = ( location.pathname ).includes(props.to);
	let className = isActive ? 'selected-link' : '';

	const estilos = {};

	return(
		<Link {...props} className={"nav-button " + className} style={estilos} >
			{props.children}
		</Link>
	);
};


const ButtonComponent = props => {
	useEffect(() => {}, []);
	const style = {
		// width: props.width ? props.width : "100px" ,
		// height: props.height ? props.height : "100px"
	};


	return (
		<div style={style} className="button">
			{props.link && (
				<NavLink
					to={props.path}
					className="nav-button"
					activeClassName="selected-link"
					onClick={props.onClick}
				>
					<div className="image-nav-button">
						<div style={{width:"30px", textAlign:"center",marginRight:"10px"}}><img style={{height:"20px", objectFit:"contain",width:"auto",margin:"auto"}} src={props.image} alt={props.text} /></div>
						<p style={{fontSize:"1em"}}>{props.text}</p>
					</div>
				</NavLink>
			)}
			{props.button && (
				<Button onClick={props.onClick} style={props.style}>
					{props.text}
				</Button>
			)}
		</div>
	);
};

export default ButtonComponent;
