import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { CLIENTE } from "../constants";

const loadCategories = loadRequest(CLIENTE);
const insertCliente = insertRequest(CLIENTE);
const updateCliente = updateRequest(CLIENTE);
const trashCliente = trashRequest(CLIENTE);
const untrashCliente = untrashRequest(CLIENTE);
const removeCliente = removeRequest(CLIENTE);

function* rootSaga() {
	yield all([
		loadCategories(),
		insertCliente(),
		updateCliente(),
		removeCliente(),
		trashCliente(),
		untrashCliente()
	]);
}
export default rootSaga;