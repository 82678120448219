import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { CREDITO } from "../constants";

const loadCreditos = loadRequest(CREDITO);
const insertCredito = insertRequest(CREDITO);
const updateCredito = updateRequest(CREDITO);
const trashCredito = trashRequest(CREDITO);
const untrashCredito = untrashRequest(CREDITO);
const removeCredito = removeRequest(CREDITO);

function* rootSaga() {
	yield all([
		loadCreditos(),
		insertCredito(),
		updateCredito(),
		removeCredito(),
		trashCredito(),
		untrashCredito()
	]);
}
export default rootSaga;