import React, {useState} from "react";
import store from "store/reducers/index";
import { Button } from "@material-ui/core";
import * as api from "api";
const CerrarSesionButton = props => {

	const logOut = () => {
		window.localStorage.removeItem("plena:autor");
		console.log({api})
		api.logOut();
		// logOut();
	};

	return (
		<>
			<Button 
				className="button-header"
				style={{background: "#fafafa", fontSize:"9pt", fontWeight:"bold"}}
				variant="contained" 
				onClick={()=>logOut()}>
        Cerrar sesión
			</Button>
		</>
	);
};

export default CerrarSesionButton;