import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { COMPRA } from "../constants";

const loadCompra = loadRequest(COMPRA);
const insertCompra = insertRequest(COMPRA);
const updateCompra = updateRequest(COMPRA);
const trashCompra = trashRequest(COMPRA);
const untrashCompra = untrashRequest(COMPRA);
const removeCompra = removeRequest(COMPRA);

function* rootSaga() {
	yield all([
		loadCompra(),
		insertCompra(),
		updateCompra(),
		removeCompra(),
		trashCompra(),
		untrashCompra()
	]);
}
export default rootSaga;