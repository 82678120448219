import { all } from "redux-saga/effects";

import acabados from "./acabados";
import clientes from "./clientes";
import compras from "./compras";
import creditos from "./creditos";
import envios from "./envios";
import medidas from "./medidas";
import pedidos from "./pedidos";
import productos from "./productos";
import {watchDownloadFileChannel} from "./generators";

// classes(),
function* rootSaga() {
	yield all([acabados(), clientes(), compras(),  creditos(), envios(), medidas(),pedidos(), productos(), watchDownloadFileChannel()]);
}
export default rootSaga;
