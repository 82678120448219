import React, { useState } from "react";

import { Modal, Backdrop, Fade } from "@material-ui/core";

import RefreshButton from "./RefreshButton";
import CerrarSesionButton from "./CerrarSesionButton";

import styles from "Styles.module.scss";
import { useWindowSize } from "../../hooks";


import "./Header.scss";



const Header = props => {


	const {innerWidth} = useWindowSize();

	const [openModal, setOpenModal] = useState(false);


	const handleModal = () => {
		setOpenModal(!openModal);
	};


	return (
		<>
			<div className="container-header" style={{display: "flex", alignItems: "center"}}>
				<div className="left">
					<h2 className="title">{props.title}</h2>
				</div>
				{innerWidth > 769 &&
						
				<div className="right botones">
					<RefreshButton />
					<CerrarSesionButton />
				</div>
				}
			</div>
			<Modal
				open={openModal}
				onClose={handleModal}
				className={styles.modal}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
			>
				<Fade in={openModal}>
					<div className={styles.paper}>
						<p>Modal</p>
					</div>
				</Fade>
			</Modal>
		</>
	);
};

export default Header;
