import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import EnvioModal from "./Envio";
import { ENVIO } from "store/constants";
import Header from "../../components/Header/Header";

const TabTareas = () => {
	const data = useSelector(state => state[ENVIO.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Titulo",
				accessor: "titulo"
			},
			{
				Header: "Precio",
				accessor: "precio"
			}
			
		],
		[]
	);

	return (
		<>
			<Header title="Envios" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={ENVIO}
				modal={EnvioModal}
				filters={columns}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabTareas;
