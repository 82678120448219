import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";



import App from "./App.js";
import { Provider } from "react-redux";
import store from "./store/reducers/index";
import messaging from "./my_firebase";
import { ThemeProvider } from "@material-ui/styles";
import Theme from "./Theme";
import {init} from "api/loader";
import * as serviceWorker from "./serviceWorker";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import "Styles.module.scss";
import $ from "jquery"; 
window.$ = $;
init();

ReactDOM.render(
	<ErrorBoundary>
		<ThemeProvider theme={Theme}>
			<Provider store={store}>
				<App />
			</Provider>
		</ThemeProvider>
	</ErrorBoundary>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register({
	onRegister:(registration)=>{
		messaging.useServiceWorker(registration);
	}
});