import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import RefCell from "../../components/Table/CellTypes/RefCell/RefCell";
import { useSelector } from "react-redux";
import ClienteModal from "./Cliente";
import {  CLIENTE } from "store/constants";
import Header from "../../components/Header/Header";

const TabTareas = () => {
	const data = useSelector(state => state[CLIENTE.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			},
			{
				Header: "Correo",
				accessor: "correo"
			},
			{
				Header: "Creditos",
				accessor: "creditos"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Creditos" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={CLIENTE}
				modal={ClienteModal}
				filters={columns}
				options={{ orderBy: true, disableInsert:true, disableDelete:true }}
			/>
		</>
	);
};

export default TabTareas;
