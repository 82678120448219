import React from "react";
import CrudTable from "../../components/Table/CrudTable/CrudTable";
import { useSelector } from "react-redux";
import CreditoModal from "./Credito";
import { CREDITO } from "store/constants";
import Header from "../../components/Header/Header";

const TabTareas = () => {
	const data = useSelector(state => state[CREDITO.name]);

	const columns = React.useMemo(
		() => [
			{
				Header: "Nombre",
				accessor: "nombre"
			}
		],
		[]
	);

	return (
		<>
			<Header title="Creditos" video time="5:35" />

			<CrudTable
				data={data.data}
				status={data.view}
				columns={columns}
				type={CREDITO}
				modal={CreditoModal}
				filters={columns}
				options={{ orderBy: true }}
			/>
		</>
	);
};

export default TabTareas;
