import React, {useState, useEffect} from "react";
import Button from "../../components/Buttons/Button";
import ButtonMaterialUi from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";

import "./NavBar.scss";

import Pacientes from "../../assets/img/icons/pacientes.png";

import Logo from "../../assets/img/logo/logo.png";

import { useWindowSize } from "../../hooks";
import RefreshButton from "components/Header/RefreshButton";
import CerrarSesionButton from "components/Header/CerrarSesionButton";

const NavBar = () => {
  
	const estilos = {};


	const {innerWidth} = useWindowSize();

	const [collapsed, setCollapsed] = useState(innerWidth < 769 );

	useEffect(() => {
		setCollapsed( innerWidth < 769 );
	}, [innerWidth]);

	const onClick = () => {
		if(innerWidth < 769) setCollapsed(!collapsed);
	};

	return (
		<div className="container-navbar" style={estilos}>
			<div className="logo-navbar">
				
					
				<img src={Logo} alt="logo" />
				
				
				{
					innerWidth < 769 &&
        <div className="button-collapsed">
        	<ButtonMaterialUi onClick={()=> {setCollapsed(!collapsed);} }><MenuIcon style={{color:"white"}} /></ButtonMaterialUi>
        </div>
				}
			</div>

			{
				!collapsed &&
          <div className={"container-routes"}>
          	{/* <Button onClick={onClick} path="/categorias" text="Categorias" image={Pacientes} link /> */}
          	{/* <Button onClick={onClick} path="/autores" text="Autores" image={Pacientes} link /> */}
          	<Button onClick={onClick} path="/acabados" text="Acabados" image={Pacientes} link />
          	<Button onClick={onClick} path="/clientes" text="Clientes" image={Pacientes} link />
          	<Button onClick={onClick} path="/compras" text="Compras de creditos" image={Pacientes} link />
          	<Button onClick={onClick} path="/creditos" text="Creditos" image={Pacientes} link />
          	<Button onClick={onClick} path="/envios" text="Tipos de envios" image={Pacientes} link />
          	<Button onClick={onClick} path="/medidas" text="Medidas" image={Pacientes} link />
          	<Button onClick={onClick} path="/pedidos" text="Pedidos" image={Pacientes} link />
          	
          	{innerWidth < 769 &&
              <>
              	<RefreshButton />
              	<CerrarSesionButton />
              </>
          	}
          </div>
			}
		</div>
	);
};

export default NavBar;
