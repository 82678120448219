export default [
	{ value: "verduras", name: "verduras", label: "Verduras", color: "#8EBF64", "cal":25, "proteinas":2, "lipidos":0, "hidratos_de_carbono":4, "conteo_hc":0.3 },
	{ value: "frutas", name: "frutas", label: "Frutas", color: "#E572A9", "cal":60, "proteinas":0, "lipidos":0, "hidratos_de_carbono":15, "conteo_hc":1 },
	{ value: "cereales", name: "cereales", label: "Cereales y tubérculos", color: "#A27C4F", "cal":70, "proteinas":2, "lipidos":0, "hidratos_de_carbono":15, "conteo_hc":1 },
	{ value: "leguminosas", name: "leguminosas", label: "Leguminosas", color: "#474997", "cal":120, "proteinas":8, "lipidos":1, "hidratos_de_carbono":20, "conteo_hc":1.3 },
	{ value: "proteinas", name: "proteinas", label: "Alimentos de origen animal", color: "#D96761", "cal":75, "proteinas":7, "lipidos":5, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "lacteos", name: "lacteos", label: "Leche", color: "#59BAC9", "cal":110, "proteinas":9, "lipidos":4, "hidratos_de_carbono":12, "conteo_hc":0.8 },
	{ value: "grasas", name: "grasas", label: "Aceites y grasas", color: "#E7C043", "cal":45, "proteinas":0, "lipidos":5, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "azucares", name: "azucares", label: "Azúcares", color: "#EA754F", "cal":40, "proteinas":0, "lipidos":0, "hidratos_de_carbono":10, "conteo_hc":0.7 },
	{ value: "agua", name: "agua", label: "Agua", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	// { name: "preparados", label: "Alimentos preparados", color: "#474997" }
];
export const catEquivalenciasMap = {
	verduras: "Verduras", 
	frutas:"Frutas",
	cereales: "Cereales y tubérculos",
	leguminosas:"Leguminosas",
	proteinas:"Alimentos de origen animal",
	lacteos:"Leche",
	grasas:"Aceites y grasas",
	azucares:"Azúcares",
	alcohol:"Alcohol", 
	libres:"Libres",
	preparados:"Alimentos preparados"
};
export const catEquivalencias = [
	{ value: "verduras", name: "verduras", label: "Verduras", color: "#8EBF64", "cal":25, "proteinas":2, "lipidos":0, "hidratos_de_carbono":4, "conteo_hc":0.3 },
	{ value: "frutas", name: "frutas", label: "Frutas", color: "#E572A9", "cal":60, "proteinas":0, "lipidos":0, "hidratos_de_carbono":15, "conteo_hc":1 },
	{ value: "cereales", name: "cereales", label: "Cereales y tubérculos", color: "#A27C4F", "cal":70, "proteinas":2, "lipidos":0, "hidratos_de_carbono":15, "conteo_hc":1 },
	{ value: "leguminosas", name: "leguminosas", label: "Leguminosas", color: "#474997", "cal":120, "proteinas":8, "lipidos":1, "hidratos_de_carbono":20, "conteo_hc":1.3 },
	{ value: "proteinas", name: "proteinas", label: "Alimentos de origen animal", color: "#D96761", "cal":75, "proteinas":7, "lipidos":5, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "lacteos", name: "lacteos", label: "Leche", color: "#59BAC9", "cal":110, "proteinas":9, "lipidos":4, "hidratos_de_carbono":12, "conteo_hc":0.8 },
	{ value: "grasas", name: "grasas", label: "Aceites y grasas", color: "#E7C043", "cal":45, "proteinas":0, "lipidos":5, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "azucares", name: "azucares", label: "Azúcares", color: "#EA754F", "cal":40, "proteinas":0, "lipidos":0, "hidratos_de_carbono":10, "conteo_hc":0.7 },
	{ value: "alcohol", name: "alcohol", label: "Alcohol", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "libres", name: "libres", label: "Libres", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	{ value: "preparados", name: "preparados", label: "Alimentos preparados", color: "#49BADD", "cal":0, "proteinas":0, "lipidos":0, "hidratos_de_carbono":0, "conteo_hc":0 },
	// { name: "preparados", label: "Alimentos preparados", color: "#474997" }
];
export const medidas = [
	{ value: "paquete", label: "Paquete" },
	{ value: "sobre", label: "Sobre" },
	{ value: "bolsa", label: "Bolsa" },
	{ value: "frasco", label: "Frasco" },
	{ value: "rebanada", label: "Rebanada" },
	{ value: "pieza", label: "Pieza" },
	{ value: "taza", label: "Taza" },
	{ value: "cucharada", label: "Cucharada" },
	{ value: "cucharita", label: "Cucharita" },
	{ value: "gramos", label: "Gramos" },
	{ value: "ml", label: "Mililitros" },
];
