export const READY = "READY";
export const LOADING = "LOADING";
export const LOADED = "LOADED";
export const LOAD_FAILED = "LOAD_FAILED";

export const SAVING = "SAVING";
export const SAVED = "SAVED";
export const SAVE_FAILED = "SAVE_FAILED";

export const INSERTING = "INSERTING";
export const INSERTED = "INSERTED";
export const INSERT_FAILED = "INSERT_FAILED";

export const UPDATING = "UPDATING";
export const UPDATED = "UPDATED";
export const UPDATE_FAILED = "UPDATE_FAILED";

export const DELETING = "DELETING";
export const DELETED = "DELETED";
export const DELETE_FAILED = "DELETE_FAILED";

export const LOAD = "LOAD_";
export const INSERT = "INSERT_";
export const UPDATE = "UPDATE_";
export const REMOVE = "REMOVE_";
export const TRASH = "TRASH_";
export const UNTRASH = "UNTRASH_";

export const ACABADO = {url:"acabados/", name:"ACABADO", label:"Acabado"};
export const CLIENTE = {url:"clientes/", name:"CLIENTE", label:"Cliente"};
export const COMPRA = {url:"compras/", name:"COMPRA", label:"Compra"};
export const CREDITO = {url:"creditos/", name:"CREDITO", label:"Credito"};
export const ENVIO = {url:"envios/", name:"ENVIO", label:"Envio"};
export const MEDIDA = {url:"medidas/", name:"MEDIDA", label:"Medida"};
export const PEDIDO = {url:"pedidos/", name:"PEDIDO", label:"Pedido"};
export const PRODUCTO = {url:"productos/", name:"PRODUCTO", label:"Producto"};


export const makeStatusFromType = type => type.toUpperCase() + "_STATUS";
