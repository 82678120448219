import React from "react";

import "./Tooltip.scss";

import { Tooltip, Icon } from "@material-ui/core";

export default function InfoTooltip({info}){
	return(
		<>
			<Tooltip className="tooltip" title={info}>
				<Icon style={{color:"#000"}}>info_icon</Icon>
			</Tooltip>
		</>
	);
}