import { all } from "redux-saga/effects";
import { loadRequest, insertRequest, updateRequest, removeRequest, trashRequest, untrashRequest } from "./generators";
import { PRODUCTO } from "../constants";

const loadProductos = loadRequest(PRODUCTO);
const insertProducto = insertRequest(PRODUCTO);
const updateProducto = updateRequest(PRODUCTO);
const trashProducto = trashRequest(PRODUCTO);
const untrashProducto = untrashRequest(PRODUCTO);
const removeProducto = removeRequest(PRODUCTO);

function* rootSaga() {
	yield all([
		loadProductos(),
		insertProducto(),
		updateProducto(),
		removeProducto(),
		trashProducto(),
		untrashProducto()
	]);
}
export default rootSaga;