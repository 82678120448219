import React,{useState} from "react";
import {Link, Grid, Hidden, Typography, Container, 
	TextField, CssBaseline, Button, CircularProgress} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { useImmer } from "use-immer";
import {post} from "api";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

const useStyles = makeStyles(theme => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
}));

export default function SignIn() {
	const classes = useStyles();
	const [user, setUser] = useImmer({clave:"",correo:""});
	const [status, setStatus] = useState({status:"ready"});

	const handleLogin = (e) => {
		e.preventDefault();
		setStatus({status:"loading"});
		post("nutritionist/forgot",user).then(()=>{
			setStatus({status:"success",message:"Se te ha enviado un correo para resetear tu contraseña"});
		}).catch(e=>{
			console.log(e);
			setStatus({status:"error",message:e});
		});
		console.log(user);
	};
	return (
		<Grid container spacing={3}>
        
			
			<Grid item sm={12}>
				<Container component="main" maxWidth="xs">
					<CssBaseline />
					<div className={classes.paper}>
						<Button color="primary" href="/login" variant="body2" style={{ alignSelf: "flex-start"}}>
							<KeyboardBackspaceIcon />Volver al Login
						</Button>
						<img src={require("assets/img/logo/logo_nombre.png")}
							alt="imagen principal"
							style={{ 
								height: "130px",
								marginBottom:"30px",
								objectFit: "cover"}}
						/>
						<Typography style={{textAlign:"center"}} color="primary" component="h1" variant="h5">
              Recuperar contraseña
						</Typography>
						<Typography style={{textAlign:"center"}} color="secondary">
							Ingresa tus datos para poder enviarte un correo con las instrucciones para cambiar tu contraseña.
						</Typography>
						
						<form className={classes.form} noValidate>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="usuario"
								label="Código de nutriólogo"
								name="email"
								autoComplete="email"
								autoFocus
								value={user.clave}
								onChange={(e)=>{
									e.persist();
									setUser((draft)=>{draft.clave = e.target.value;});
								}}
							/>
							<TextField
								variant="outlined"
								margin="normal"
								required
								fullWidth
								id="email"
								label="Correo"
								name="email"
								autoComplete="email"
								autoFocus
								value={user.correo}
								onChange={(e)=>{
									e.persist();
									setUser((draft)=>{draft.correo = e.target.value;});
								}}
							/>
							
							{status.status==="error" &&
                  <Alert severity="error">{status.message}</Alert>
							}
							{status.status==="success" &&
                <Alert severity="success">
									<div>{status.message}</div>
									<Link href="/login" variant="body2">
										Ir a login
									</Link>
								</Alert>
							}
							{status.status==="loading" ?
								<CircularProgress />
								:
								<Button
									type="submit"
									fullWidth
									variant="contained"
									color="primary"
									onClick={handleLogin}
								>
                    Recuperar
								</Button>
							}

						</form>
					</div>
				</Container>
			</Grid>
		</Grid>
     
    
	);
}