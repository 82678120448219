import React from "react";
import { Route } from "react-router-dom";

import "./Routes.scss";


import Home from "../pages/Home/Home";
import NavBar from "../pages/NavBar/NavBar";
import TablaAcabados from "../pages/Acabados/TablaAcabados";
import TablaClientes from "../pages/Clientes/TablaClientes";
import TablaCompras from "../pages/Compras/TablaCompras";
import TablaCreditos from "../pages/Creditos/TablaCreditos";
import TablaEnvios from "../pages/Envios/TablaEnvios";
import TablaMedidas from "../pages/Medidas/TablaMedidas";
import TablaPedidos from "../pages/Pedidos/TablaPedidos";

import Login from "../pages/Login/Login";
import Registro from "../pages/Login/Registro";
import Forgot from "../pages/Login/Olvide";
import Reset from "../pages/Login/Reset";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import PublicRoute from "./PublicRoute/PublicRoute";

// import usePrevious from "Hooks/usePrevious";

const Routes = () => {
	// const prevPath = usePrevious(window.location.pathname);

	let RNavBar = window.location.pathname === "/login" || window.location.pathname === "/registro" || window.location.pathname.startsWith("/reset") || window.location.pathname.startsWith("/reset") || window.location.pathname === "/forgot" || window.location.origin === "/consultaonline" ? null : <NavBar />;
	return (
		<>
			<div className="container">
				<PublicRoute exact path="/login" component={Login} />
				<PublicRoute exact path="/registro" component={Registro} />
				<PublicRoute exact path="/forgot" component={Forgot} />
				<PublicRoute exact path="/reset/:token/:idUsuario" component={Reset} />

				<div className={(window.location.pathname === "/login" || window.location.pathname === "/registro"||window.location.pathname === "/forgot") ? "": "container-app"}>
					{/* <Header /> */}
					{RNavBar}

					<PrivateRoute exact path="/" component={Home} />
					{/* <PrivateRoute path="/categorias" component={TablaCategorias} /> */}
					{/* <PrivateRoute path="/autores" component={TablaAutores} /> */}
					<PrivateRoute path="/acabados" component={TablaAcabados} />
					<PrivateRoute path="/clientes/" component={TablaClientes} />
					<PrivateRoute path="/compras/" component={TablaCompras} />
					<PrivateRoute path="/creditos/" component={TablaCreditos} />
					<PrivateRoute path="/envios/" component={TablaEnvios} />
					<PrivateRoute path="/medidas/" component={TablaMedidas} />
					<PrivateRoute path="/pedidos/" component={TablaPedidos} />


				</div>
			</div>
		</>
	);
};

export default Routes;
